import 'normalize.css'
import 'styles/index.scss'

import './scroll'
import './hover'

import Flickity from 'flickity'
require('flickity-imagesloaded')

import '@lottiefiles/lottie-player'
import * as LottieInteractivity from '@lottiefiles/lottie-interactivity'


/*
 * Background color change
 */

var bodyEl = $("body");

$(window).on("scroll", function() {
  var scrollTop = $(this).scrollTop();
  var scrollBottom = $(window).scrollTop() + $(window).height() / 2;

  $("section").each(function() {
    var el = $(this),
        className = el.attr("id");
    if (el.offset().top < scrollBottom) {
      bodyEl.addClass(className);  
    } else {
      bodyEl.removeClass(className);
    }
  });
});






/*
 * Lottie
 */

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('one')) {
    LottieInteractivity.create({
      mode: 'scroll',
      player: '#one',
      actions: [
        {
          visibility: [0.2, 1],
          type: 'seek',
          frames: [0, 30],
        },
      ],
    })
  }

  if (document.getElementById('two')) {
    LottieInteractivity.create({
      mode: 'scroll',
      player: '#two',
      actions: [
        {
          visibility: [0.2, 1],
          type: 'seek',
          frames: [0, 30],
        },
      ],
    })
  }

  if (document.getElementById('fortyfive')) {
    LottieInteractivity.create({
      mode: 'scroll',
      player: '#fortyfive',
      actions: [
        {
          visibility: [0, 1],
          type: 'seek',
          frames: [0, 45],
        },
      ],
    })
  }

  if (document.getElementById('c1824-1')) {
    LottieInteractivity.create({
      mode: 'scroll',
      player: '#c1824-1',
      actions: [
        {
          visibility: [-0.8, 1],
          type: 'seek',
          frames: [0, 15],
        },
      ],
    })
  }

  if (document.getElementById('c1824-2')) {
    LottieInteractivity.create({
      mode: 'scroll',
      player: '#c1824-2',
      actions: [
        {
          visibility: [-0.8, 1],
          type: 'seek',
          frames: [0, 15],
        },
      ],
    })
  }

  if (document.getElementById('c1824-3')) {
    LottieInteractivity.create({
      mode: 'scroll',
      player: '#c1824-3',
      actions: [
        {
          visibility: [-0.8, 1],
          type: 'seek',
          frames: [0, 15],
        },
      ],
    })
  }
})



/*
 * Flickity Project Gallery
 */
const projectGalleryTickerSpeed = 0.75

const projectGalleries = document.querySelectorAll('.project-gallery')

const autoScrollFlickity = (flkty) => {
  flkty.x = Math.floor((flkty.x - projectGalleryTickerSpeed) % flkty.slideableWidth)
  flkty.selectedIndex = flkty.dragEndRestingSelect()
  flkty.updateSelectedSlide()
  flkty.settle(flkty.x)

  window.requestAnimationFrame(() => autoScrollFlickity(flkty))
}

projectGalleries.forEach((el) => {
  const flkty = new Flickity(el, {
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    wrapAround: true,
    imagesLoaded: true,
    selectedAttraction: 0.015,
    friction: 0.25,
  })

  autoScrollFlickity(flkty)
})

/*
 * Intro Words
 */

var words = document.getElementById('words');
words.innerHTML = words.innerHTML.replace(/(^|<\/?[^>]+>|\s+)([^\s<]+)/g, '<span class="item">$1 $2</span>');

// ScrollReveal().reveal('.item', {
//   delay: 300,
//   duration: 1000,
//   opacity: .15,
//   interval: 100,
//   reset: false
// });