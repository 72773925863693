const items = document.querySelectorAll('.home-projects')
const image = document.querySelector('.image-reveal')

items.forEach((el) => {
  el.addEventListener('mouseover', (e) => {
    imageData = e.target.closest('.home-projects').getAttribute('data-image')
    image.setAttribute('src', imageData)
    image.style.opacity = 1
  })
  el.addEventListener('mousemove', (e) => {
    image.style.top = e.clientY + 'px'
    image.style.left = e.clientX + 'px'
  })
  el.addEventListener('mouseleave', (e) => {
    image.style.opacity = 0
    e.target.style.zIndex = 0
    image.setAttribute('src', '')
  })

  el.addEventListener('touchstart', (e) => {
    imageData = e.target.closest('.home-projects').getAttribute('data-image')
    image.setAttribute('src', imageData)
    image.style.opacity = 1
  })
  el.addEventListener('touchmove', (e) => {
    image.style.top = e.touches[0].clientY + 'px'
    image.style.left = e.touches[0].clientX + 'px'
  })
  el.addEventListener('touchend', (e) => {
    image.style.opacity = 0
    e.target.style.zIndex = 0
    image.setAttribute('src', '')
  })
})